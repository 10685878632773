import { Link } from "gatsby";
import React from "react";

export function GetInContact() {
  return <div className="content-wrapper content-wrapper--contact-banner secondary">
    <div className="content pure-g">
      <div className="l-box pure-u-1">
        <h2 className={'hero-heading'}>Sie wollen Ihre Kunden mit digitalen Produkten begeistern? Wir unter&shy;stützten Sie dabei. Erfahren Sie
          mehr <Link to="/uber-uns">über&nbsp;uns</Link>.</h2>
      </div>
    </div>
  </div>;
}