import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { HeroText } from '../components/HeroText';

// Images
import imageUsabilityReview from '../images/usability-review.jpg'
import imageUsabilityTest from '../images/usability-test.jpg'
import imageUsabilityDesign from '../images/usability-design.gif'
import { GetInContact } from "../components/getInContact";

const UsabilityPage = () => (
  <Layout activeMenuItem={'usability'}>
    <Seo
      title="Usability"
      keywords={[`usability`]}
      description={'Sie erhalten konkrete Lösungsvorschläge, um die Benutzerfreundlichkeit Ihres Produkts sofort zu verbessern.'}
    />

    <HeroText>Sie erhalten konkrete Lösungs&shy;vorschläge, um die Benutzer&shy;freundlich&shy;keit Ihres Produkts
      sofort zu verbessern.</HeroText>

    <div className="content-wrapper usability-services">
      <div className="content pure-g">
        <div className="pure-u-1 pure-u-md-1-3">
          <img src={imageUsabilityReview} alt="Usability-Review" />
        </div>
        <div className="pure-u-1 pure-u-md-2-3">
          <h2>Usability-Review</h2>
          <p className="muted">5.000 bis 8.000 EUR (Umfang der Analyse wird vorab festgelegt)</p>
          <p>Wir analysieren ihre Website, App oder Software-Anwendung anhand etablierter Design-Richtlinien. Sie
            erhalten eine Liste aller Usability-Schwächen inklusive konkreter Lösungsvorschläge, welche wir in einem
            Workshop mit ihrem Entwicklerteam besprechen. Usability-Reviews sind die schnellste und günstigste Methode,
            um die Probleme Ihrer bestehenden Anwendung zielgerichtet zu lösen.</p>
        </div>
      </div>
      <div className="content pure-g">
        <div className="pure-u-1 pure-u-md-1-3">
          <img src={imageUsabilityTest} alt="Remote-Usability-Test" />
        </div>
        <div className="pure-u-1 pure-u-md-2-3">
          <h2>Remote-Usability-Test</h2>
          <p className="muted">8.000 EUR (Apps) bis 15.000 EUR (komplexe Websites und Anwendungen)</p>
          <p>Aus unserem Pool von Usability-Testern wählen wir Personen, die in Ihr Profil passen und beobachten sie
            dabei, wie sie Ihr Produkt verwenden. Wir rekrutieren die Probanden, übernehmen die Abwicklung, Auswertung
            und Analyse der Usability-Tests und liefern Ihnen ein Dokument mit konkreten Handlungsempfehlungen für einen
            gemeinsamen Workshop mit Ihrem Entwickler-Team.</p>
        </div>
      </div>
      <div className="content pure-g">
        <div className="pure-u-1 pure-u-md-1-3">
          <img src={imageUsabilityDesign} alt="User-Interface-Design" />
        </div>
        <div className="pure-u-1 pure-u-md-2-3">
          <h2>Laufende Usability-Optimierungen</h2>
          <p className="muted">150 EUR je Arbeitsstunde</p>
          <p>Gemeinsam mit Ihnen definieren wir wirtschaftlich relevante Teile Ihrer Website oder Ihrer web-basierten
            Anwendung und entwickeln dafür ein Re-Design. Diese vergleichen wir zum Beispiel in A/B-Tests, und bestimmen
            auf diese Weise einen Sieger. Wir können Ihre gesamte Website kontinuierlich optimieren und immer besser an
            die Bedürfnisse Ihrer Kunden anpassen.</p>
          <Link to="/design">Erfahren Sie mehr über unseren Design-Prozess</Link>
        </div>
      </div>
    </div>
    <GetInContact />
  </Layout>
);

export default UsabilityPage
